import React, { Suspense, lazy } from 'react';
import { Route as DefaultRoute, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import Loading from './Loading';

import AdminRoutes from '../../admin/components/Routes';
import ClientRoutes from '../../client/components/Routes';

// Don't lazy load error pages in case there's no network connection
import ServerError from './ServerError';
import PermissionError from './PermissionError';
import NotFoundError from './NotFoundError';
import ApplicationError from './ApplicationError';

const Home = lazy(() => import('../../public/components/home/Home'));
const RegisterForm = lazy(() => import('../../public/components/register/RegisterForm'));
const RegisterSuccess = lazy(() => import('../../public/components/register/RegisterSuccess'));
const SetPassword = lazy(() => import('../../public/components/login/SetPassword'));
const LoginForm = lazy(() => import('../../public/components/login/LoginForm'));
const ForgotPassword = lazy(() => import('../../public/components/login/ForgotPassword'));
const ResetPassword = lazy(() => import('../../public/components/login/ResetPassword'));
const RegisterMyCampground = lazy(() => import('../../public/components/pages/campRegister/RegisterMyCampground'));

const AlbertaLandingPage = lazy(() => import('../../public/components/regions/AlbertaLandingPage'));
const BritishColumbiaLandingPage = lazy(() => import('../../public/components/regions/BritishColumbiaLandingPage'));
const ManitobaLandingPage = lazy(() => import('../../public/components/regions/ManitobaLandingPage'));
const NewBrunswickLandingPage = lazy(() => import('../../public/components/regions/NewBrunswickLandingPage'));
const NovaScotiaLandingPage = lazy(() => import('../../public/components/regions/NovaScotiaLandingPage'));
const OntarioLandingPage = lazy(() => import('../../public/components/regions/OntarioLandingPage'));
const QuebecLandingPage = lazy(() => import('../../public/components/regions/QuebecLandingPage'));
const SaskatchewanLandingPage = lazy(() => import('../../public/components/regions/SaskatchewanLandingPage'));
const NewfoundlandLandingPage = lazy(() => import('../../public/components/regions/NewfoundlandLandingPage'));

const EventSearch = lazy(() => import('../../public/components/event/EventSearch'));

const CampIndexContainer = lazy(() => import('../../public/components/camps/CampIndexContainer'));
const CampViewContainer = lazy(() => import('../../public/components/camps/CampViewContainer'));
const CampClaimListingContainer = lazy(() => import('../../public/components/camps/CampClaimListingContainer'));
const EventViewContainer = lazy(() => import('../../public/components/event/EventViewContainer'));

const SiteSearchContainer = lazy(() => import('../../public/components/camps/SiteSearchContainer'));

const CartCheckoutContainer = lazy(() => import('../../profile/components/cart/checkout/CartCheckoutContainer'));
const CartThanksContainer = lazy(() => import('../../profile/components/cart/checkout/CartThanksContainer'));

const BookingIndexContainer = lazy(() => import('../../profile/components/booking/BookingIndexContainer'));
const BookingViewContainer = lazy(() => import('../../profile/components/booking/BookingViewContainer'));
const BookingCancelConfirmContainer = lazy(() => import('../../profile/components/booking/BookingCancelConfirmContainer'));
const BookingInfoEditContainer = lazy(() => import('../../profile/components/booking/BookingInfoEditContainer'));
const BookingAddOnEditContainer = lazy(() => import('../../profile/components/booking/BookingAddOnEditContainer'));
const BookingAdditionalQuestionEditContainer = lazy(() => import('../../profile/components/booking/AdditionalQuestionEditContainer'));
const BookingVehicleInfoEditContainer = lazy(() => import('../../profile/components/booking/VehicleInfoEditContainer'));
const BookingReservationEditContainer = lazy(() => import('../../profile/components/booking/BookingReservationEditContainer'));
const BookingPaymentAddContainer = lazy(() => import('../../profile/components/booking/BookingPaymentAddContainer'));

const EventRegistrationIndexContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationIndexContainer'));
const EventRegistrationViewContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationViewContainer'));
const EventRegistrationPaymentAddContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationPaymentAddContainer'));
const EventRegistrationCancelConfirmContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationCancelConfirmContainer'));
const EventRegistrationInfoEditContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationInfoEditContainer'));
const EventRegistrationTicketEditContainer = lazy(() => import('../../profile/components/eventRegistrations/EventRegistrationTicketEditContainer'));


const CartViewContainer = lazy(() => import('../../profile/components/cart/CartViewContainer'));

const UserViewContainer = lazy(() => import('../../profile/components/user/UserViewContainer'));
const UserEditContainer = lazy(() => import('../../profile/components/user/UserEditContainer'));

const PublicLayout = lazy(() => import('../../public/components/PublicLayout'));

const Contact = lazy(() => import('../../public/components/contact/Contact'));
const ContactThanks = lazy(() => import('../../public/components/contact/ContactThanks'));
const TermsAndConditions = lazy(() => import('../../public/components/TermsAndConditions'));
const CampTermsAndConditionsContainer = lazy(() => import('../../public/components/camps/CampTermsAndConditionsContainer'));

const StripeConnect = lazy(() => import('../../client/components/StripeConnect'));

function Route(props) {
  const { component: Component, path, authenticated } = props;
  const newComponent = (routeProps) => (
    <PublicLayout path={path}>
      <Component {...routeProps} />
    </PublicLayout>
  );

  if (authenticated) {
    return (<AuthenticatedRoute {...props} component={newComponent} />);
  }

  return (<DefaultRoute {...props} component={newComponent} />);
}

function Routes() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/contact/thanks" component={ContactThanks} />
        <Route exact path="/terms-conditions" component={TermsAndConditions} />
        <DefaultRoute exact path="/campground-sign-up" component={RegisterMyCampground} />

        {/* Camp Routes */}
        <Route exact path="/camps" component={CampIndexContainer} />
        <Route exact path="/camps/:id" component={CampViewContainer} />
        <Route exact path="/camps/:id/sites/search" component={SiteSearchContainer} />
        <Route exact path="/camps/:id/terms-conditions" component={CampTermsAndConditionsContainer} />
        <Route exact path="/camps/:id/events/:eventId" component={EventViewContainer} />
        <DefaultRoute exact path="/camps/:id/claim" component={CampClaimListingContainer} />

        {/* Events */}
        <Route exact path="/events" component={EventSearch} />

        {/* User Routes */}
        <Route exact path="/register" component={RegisterForm} />
        <Route exact path="/register/success/:userId" component={RegisterSuccess} />
        <Route exact path="/login" component={LoginForm} />
        <Route exact path="/set-password/:token" component={SetPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/profile" component={UserViewContainer} />
        <Route exact path="/profile/edit" component={UserEditContainer} />
        <Route exact path="/profile/bookings" component={BookingIndexContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id" component={BookingViewContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/cancel" component={BookingCancelConfirmContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/guest-info/edit" component={BookingInfoEditContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/add-ons/edit" component={BookingAddOnEditContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/additional-questions/edit" component={BookingAdditionalQuestionEditContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/vehicle-info/edit" component={BookingVehicleInfoEditContainer} />

        <Route exact path="/profile/camps/:campId/bookings/:id/reservation/edit" component={BookingReservationEditContainer} />
        <Route exact path="/profile/camps/:campId/bookings/:id/payments/new" component={BookingPaymentAddContainer} />
        <Route exact path="/profile/event-registrations" component={EventRegistrationIndexContainer} />
        <Route exact path="/profile/camps/:campId/event-registrations/:id" component={EventRegistrationViewContainer} />
        <Route exact path="/profile/camps/:campId/event-registrations/:id/payments/new" component={EventRegistrationPaymentAddContainer} />
        <Route exact path="/profile/camps/:campId/event-registrations/:id/cancel" component={EventRegistrationCancelConfirmContainer} />
        <Route exact path="/profile/camps/:campId/event-registrations/:id/guest-info/edit" component={EventRegistrationInfoEditContainer} />
        <Route exact path="/profile/camps/:campId/event-registrations/:id/tickets/edit" component={EventRegistrationTicketEditContainer} />

        <Route exact path="/profile/cart" component={CartViewContainer} />
        <Route exact path="/profile/cart/confirmation/" component={CartThanksContainer} />
        <Route exact path="/profile/cart/:campId" authenticated>
          {
            (routeProps) => (
              <CartCheckoutContainer
                campId={routeProps.match.params.campId}
                {...routeProps}
              />
            )
          }
        </Route>

        <Route exact path="/stripe-connect" component={StripeConnect} />

        {/* Region Routes */}
        <Route exact path="/alberta" component={AlbertaLandingPage} />
        <Route exact path="/british-columbia" component={BritishColumbiaLandingPage} />
        <Route exact path="/manitoba" component={ManitobaLandingPage} />
        <Route exact path="/new-brunswick" component={NewBrunswickLandingPage} />
        <Route exact path="/nova-scotia" component={NovaScotiaLandingPage} />
        <Route exact path="/ontario" component={OntarioLandingPage} />
        <Route exact path="/quebec" component={QuebecLandingPage} />
        <Route exact path="/saskatchewan" component={SaskatchewanLandingPage} />
        <Route exact path="/newfoundland-and-labrador" component={NewfoundlandLandingPage} />


        <DefaultRoute path="/admin" component={AdminRoutes} />
        <DefaultRoute path="/client" component={ClientRoutes} />

        <Route exact path="/500" component={ServerError} />
        <Route exact path="/403" component={PermissionError} />

        <Route exact path="/error" component={ApplicationError} />

        <Route component={NotFoundError} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
